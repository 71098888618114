<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "nǎ",
          hanzi: "哪",
          english: "which",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-na-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "guó",
          hanzi: "国",
          english: "country",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-guo-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "Zhōngguó rén",
          hanzi: "中国人",
          english: "Chinese (person; people)",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-zhongguoren-bubble.svg`),
          englishClass:"font-english-small",
          pinyinClass:"font-english-small",
          hanziClass:"font-english-small",
          pinyinWrap: true,
          longtext: true,

        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "shénme",
          hanzi: "什么",
          english: "what",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-shenme-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "... shì nǎ guó rén?",
          hanzi: "……是哪国人？",
          english: "What is ... nationality?",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-shi-na-guo-ren.svg`),
          blue: true,
          englishClass:"font-english-small",
          pinyinClass:"font-english-small",
          hanziClass:"font-english-small",
          pinyinWrap: true,
          longtext: true,
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
